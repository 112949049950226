import React from "react";
import { styled } from "@mui/system";
import { Ruler, RulerGuides } from "./Ruler";
import RInfiniteViewer from "react-infinite-viewer";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import { observer } from "mobx-react";
import { createShape } from "./Shape";
import { useSize } from "ahooks";
import { Store } from "../store";

const WorkspaceRoot = styled("div", {
  name: "Workspace",
  slot: "Root"
})`
  flex: 1;
  position: relative;
  width: 100%;
  height: 100%;
`;

const WorkspaceInner = styled("div", {
  name: "Workspace",
  slot: "Inner"
})`
  position: absolute;
  inset: 0 0 0 0;
  overflow: hidden;
  background-color: #c6cdd5;
`;

const HorizontalRuler = styled(Ruler)`
  position: absolute;
  top: 0;
  left: 24px;
  right: 0;
  height: 25px;
`;

const VerticalRuler = styled(Ruler)`
  position: absolute;
  top: 24px;
  bottom: 0;
  left: 0;
  width: 25px;
`;

const InfiniteViewer = styled(RInfiniteViewer)`
  &.infinite-viewer {
    position: absolute;
    inset: 25px 0 0 25px;
    overflow: hidden;
    user-select: none;
  }
`;

const HotCorner = styled("div", {
  name: "HotCorner"
})(({ theme }) => {
  return {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 2,
    width: "25px",
    height: "25px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRight: `1px solid #c2c2c2`,
    borderBottom: `1px solid #c2c2c2`,
    color: "#777",
    backgroundColor: "#eee",
    "& .MuiSvgIcon-root": {
      fontSize: "14px"
    }
  };
});

const Viewport = styled("div")(() => ({
  position: "relative",
  width: "max-content",
  height: "max-content",
  background: "#fff",
  boxShadow: "0 1px 5px rgba(0, 0, 0, 0.21)"
}));

export interface WorkspaceProps {
  store: Store;
}

export default observer(function Workspace(props: WorkspaceProps) {
  const store = props.store;
  const { horizontalGuides, verticalGuides, zoom } = store;

  const horizontalGuidesRef = React.useRef<any>();
  const verticalGuidesRef = React.useRef<any>();
  const rulerGuidesRef = React.useRef<any>();
  const infiniteViewerRef = React.useRef<any>();
  const viewportRef = React.useRef<HTMLDivElement>(null);
  const workspaceRef = React.useRef<any>();
  const workspaceSize = useSize(workspaceRef);

  const setScroll = (x: number, y: number) => {
    (horizontalGuidesRef.current as any).scroll(x);
    (verticalGuidesRef.current as any).scroll(y);
    (rulerGuidesRef.current as any).scroll({ x, y });
  };

  const scrollCenter = () => {
    (infiniteViewerRef.current as any).scrollCenter();
  };

  const resize = () => {
    horizontalGuidesRef.current?.resize();
    verticalGuidesRef.current?.resize();
  };

  // const fit = () => {
  //   const wrap = (infiniteViewerRef.current as any).wrapperElement;
  //   const content = viewportRef.current as HTMLElement;
  //
  //   const wrapWidth = wrap.clientWidth;
  //   const wrapHeight = wrap.clientHeight;
  //   const width = content.clientWidth;
  //   const height = content.clientHeight;
  //
  //   const scaleX = wrapWidth / width;
  //   const scaleY = wrapHeight / height;
  //   const scale = Math.min(scaleX, scaleY);
  //
  //   scrollCenter();
  //   store.setZoom(scale);
  // };

  const contain = () => {
    const wrap = (infiniteViewerRef.current as any).wrapperElement;
    const content = viewportRef.current as HTMLElement;

    const wrapWidth = wrap.clientWidth;
    const wrapHeight = wrap.clientHeight;
    const width = content.clientWidth;
    const height = content.clientHeight;

    const scaleX = wrapWidth / width;
    const scaleY = wrapHeight / height;
    const scale = Math.min(scaleX, scaleY, 1);

    scrollCenter();
    store.setZoom(scale);
  };

  React.useEffect(() => {
    contain();
  }, []);

  React.useEffect(() => {
    scrollCenter();
  }, [workspaceSize]);

  return (
    <WorkspaceRoot ref={workspaceRef}>
      <WorkspaceInner>
        <HotCorner onClick={scrollCenter}>
          <CenterFocusStrongIcon />
        </HotCorner>
        <HorizontalRuler
          type="horizontal"
          ref={horizontalGuidesRef}
          zoom={zoom}
          guides={horizontalGuides}
          onChangeAdder={(e: { show: any; guide: number }) => {
            (rulerGuidesRef.current as any).adder(e.show, "horizontal", e.guide);
          }}
          onChangeGuides={(guides: number[]) => {
            store.setHorizontalGuides([...guides]);
          }}
        />
        <VerticalRuler
          type="vertical"
          ref={verticalGuidesRef}
          zoom={zoom}
          guides={verticalGuides}
          onChangeAdder={(e: { show: any; guide: number }) => {
            (rulerGuidesRef.current as any).adder(e.show, "vertical", e.guide);
          }}
          onChangeGuides={(guides: number[]) => {
            store.setVerticalGuides(guides);
          }}
        />
        <InfiniteViewer
          className="infinite-viewer"
          ref={infiniteViewerRef}
          usePinch={true}
          useForceWheel={true}
          useWheelScroll={true}
          pinchThreshold={50}
          maxPinchWheel={3}
          zoom={zoom}
          displayVerticalScroll={false}
          displayHorizontalScroll={false}
          onScroll={(e: { scrollLeft: number; scrollTop: number }) => {
            setScroll(e.scrollLeft, e.scrollTop);
          }}
          onPinch={(e: { zoom: number }) => {
            store.setZoom(e.zoom);
          }}
        >
          <Viewport ref={viewportRef} style={{ width: store.screen.width, height: store.screen.height }}>
            <div
              style={{
                position: "relative",
                width: store.screen.width,
                height: store.screen.height,
                background: store.screen.background
              }}
            >
              {store.screen.children.map((element: any) => createShape(element as any, store as any))}
            </div>
          </Viewport>
        </InfiniteViewer>
        <RulerGuides
          ref={rulerGuidesRef}
          horizontalGuides={horizontalGuides}
          verticalGuides={verticalGuides}
          zoom={zoom}
        />
      </WorkspaceInner>
    </WorkspaceRoot>
  );
});
