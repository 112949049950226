import { makeObservable, action, observable } from "mobx";
import Element from "./Element";
import TextElement from "./TextElement";
import ChartElement from "./ChartElement";

const customElements = new Map();
customElements.set("text", TextElement);
customElements.set("chart", ChartElement);

interface ScreenAttrs {
  width: number;
  height: number;
  background: string;
}

export default class Screen {
  constructor() {
    makeObservable(this);
  }

  @observable children: Element[] = [];
  @observable width = 0;
  @observable height = 0;
  @observable background = "";
  @observable viewMode = "fit";

  @action
  set(attrs: Record<string, any>) {
    Object.assign(this, attrs);
  }

  createElement(attrs: any) {
    const Constructor = customElements.get(attrs.type) || Element;
    return Constructor.set(attrs);
  }

  @action
  addElement(element: Element) {
    // element.parent = this;
    const index = this.children.indexOf(element);
    if (index === -1) {
      this.children.push(element);
    }
  }

  @action
  removeElement(element: Element) {
    const index = this.children.indexOf(element);
    if (index !== -1) {
      this.children.splice(index, 1);
    }
  }
}
