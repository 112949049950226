import React from "react";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import logoSrc from "./ArtBias.png";
import { observer } from "mobx-react";

const TitlebarRoot = styled("section", {
  name: "Titlebar",
  slot: "root"
})(({ theme }) => {
  return {
    position: "relative",
    height: "24px",
    flexShrink: 0,
    // color: theme.palette.primary.contrastText,
    color: "#6b6b6b",
    backgroundColor: "#ececed"
  };
});

const Logo = styled("div")`
  width: 60px;
  height: 24px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  line-height: 24px;
  color: #161616;
  background: url("${logoSrc}") no-repeat left center;
  background-size: 60px;
  overflow: hidden;
  text-indent: -200%;
`;

const ProjectTitle = styled("div")`
  position: absolute;
  left: calc(50% - 135px);
  width: 270px;
  height: 100%;
  padding-left: 6px;
  padding-right: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #000;
`;

const TitlebarInner = styled("div", {
  name: "Titlebar",
  slot: "inner"
})(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "0 8px"
}));

export interface TitlebarProps {
  store: any;
}

function Titlebar({ store }: TitlebarProps) {
  const title = store.screen.name || "未命名";

  return (
    <TitlebarRoot>
      <TitlebarInner>
        <Logo>ArtBias</Logo>
        <ProjectTitle>{title}</ProjectTitle>
        <Stack direction="row">
          {/*<Button>功能1</Button>
          <Button>功能2</Button>*/}
        </Stack>
      </TitlebarInner>
    </TitlebarRoot>
  );
}

export default observer(Titlebar);
