import React from "react";
import { styled } from "@mui/system";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import WidgetsIcon from "@mui/icons-material/Widgets";
import StyleIcon from "@mui/icons-material/Style";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import TitleIcon from "@mui/icons-material/Title";
import ImageIcon from "@mui/icons-material/Image";
import SquareIcon from "@mui/icons-material/Square";
import BarChartIcon from "@mui/icons-material/BarChart";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { observer } from "mobx-react";

const ToolbarRoot = styled("section", {
  name: "Toolbar",
  slot: "root"
})(({ theme }) => {
  return {
    position: "relative",
    zIndex: 99,
    height: "45px",
    flexShrink: 0,
    borderBottom: "1px solid #dddddd",
    // color: theme.palette.primary.contrastText,
    color: "#6b6b6b",
    backgroundColor: "#ececed",
    boxShadow: "0 1 3px rgba(0, 0, 0, 0.1)"
  };
});

const ToolbarInner = styled("div", {
  name: "Toolbar",
  slot: "inner"
})(() => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  height: "100%",
  padding: "0 8px"
}));

export interface ToolbarProps {
  store: any;
}

function Toolbar({ store }: ToolbarProps) {
  const placeCenterElement = (attrs: any) => {
    const element = store.screen.createElement(attrs);
    element.x = store.screen.width / 2 - element.width / 2;
    element.y = store.screen.height / 2 - element.height / 2;
    const selectedElements = store.selectedElements.map((el: any) => el.id);

    store.history.push({
      redo() {
        store.screen.addElement(element);
        store.selectElements([element.id]);
      },
      undo() {
        store.screen.removeElement(element);
        store.selectElements(selectedElements);
      }
    });
  };

  return (
    <ToolbarRoot>
      <ToolbarInner>
        <Stack direction="row" alignItems="center">
          <Button startIcon={<WidgetsIcon />} variant="contained" size="small">
            对象列表
          </Button>
          <Button
            startIcon={<PlayArrowIcon />}
            variant="outlined"
            size="small"
            sx={{ position: "absolute", left: "18%" }}
          >
            预览
          </Button>
        </Stack>
        <Stack direction="row" spacing={1}>
          <Button
            startIcon={<TitleIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              placeCenterElement({
                type: "text",
                name: "文本",
                text: "文本",
                width: 100,
                height: 30
              });
            }}
          >
            文本
          </Button>
          <Button
            startIcon={<BarChartIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              placeCenterElement({
                type: "chart",
                name: "图表",
                width: 300,
                height: 200,
                option: {
                  xAxis: {
                    type: "category",
                    data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
                  },
                  yAxis: {
                    type: "value"
                  },
                  series: [
                    {
                      data: [120, 200, 150, 80, 70, 110, 130],
                      type: "bar"
                    }
                  ]
                }
              });
            }}
          >
            图表
          </Button>
          <Button
            startIcon={<ImageIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              placeCenterElement({
                type: "image",
                name: "图像",
                src: "https://mui.com/static/images/avatar/1.jpg",
                width: 50,
                height: 50
              });
            }}
          >
            图像
          </Button>
          <Button
            startIcon={<SquareIcon />}
            variant="outlined"
            size="small"
            onClick={() => {
              placeCenterElement({
                type: "svg",
                name: "形状",
                src: "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMYXllcl8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgdmlld0JveD0iMCAwIDQwMCA0MDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQwMCA0MDA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxwb2x5Z29uIHN0eWxlPSJmaWxsOiNGN0NGNTI7IiBwb2ludHM9IjE1Ny4wNTUsMCA5MC43OTgsMTk2LjMxOSAxNjQuNDE3LDE5Ni4zMTkgODguMzQ0LDQwMCAyODkuNTcxLDE1OS41MDkgMjE4LjQwNSwxNTkuNTA5IA0KCQkzMTEuNjU2LDAgCSIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=",
                width: 50,
                height: 50
              });
            }}
          >
            形状
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center">
          <Stack direction="row" sx={{ position: "absolute", right: "15%" }}>
            <ButtonGroup variant="outlined" size="small">
              <Button startIcon={<UndoIcon />} onClick={() => store.history.undo()}>
                撤销
              </Button>
              <Button startIcon={<RedoIcon />} onClick={() => store.history.redo()}>
                重做
              </Button>
            </ButtonGroup>
          </Stack>
          <Button startIcon={<StyleIcon />} variant="contained" size="small">
            检查器
          </Button>
        </Stack>
      </ToolbarInner>
    </ToolbarRoot>
  );
}

export default observer(Toolbar);
