interface PushElement {
  redo(): void | Promise<void>;
  undo(): void | Promise<void>;
  notify?(step: "push" | "redo" | "undo"): void;
  description?: string;
}

class UndoRedo {
  private _position = -1;
  private _stack: PushElement[] = [];

  /**
   * Gets the reference to the current stack of actions.
   */
  get stack() {
    return this._stack;
  }

  /**
   * Pushes the given element to the current undo/redo stack. If the current action index
   * is inferior to the stack size then the stack will be broken.
   * @param element defines the reference to the element to push in the undo/redo stack.
   */
  push(element: PushElement): void | Promise<void> {
    // Check index
    if (this._position < this._stack.length - 1) {
      this._stack.splice(this._position + 1);
    }

    // Push element and call the redo function
    this._stack.push(element);
    return this._redo("push");
  }

  /**
   * Undoes the action located at the current index of the stack.
   * If the action is asynchronous, its promise is returned.
   */
  undo(): void | Promise<void> {
    return this._undo();
  }

  /**
   * Redoes the current action located at the current index of the stack.
   * If the action is asynchronous, its promise is returned.
   */
  redo(): void | Promise<void> {
    return this._redo("redo");
  }

  /**
   * Called on an undo action should be performed.
   */
  private _undo() {
    if (this._position < 0) {
      // return shell.beep();
      return;
    }

    const element = this._stack[this._position];

    const possiblePromise = element.undo();
    if (possiblePromise instanceof Promise) {
      possiblePromise.then(() => {
        element.notify?.("undo");
      });
    } else {
      element.notify?.("undo");
    }

    this._position--;
    return possiblePromise;
  }

  /**
   * Called on a redo action should be performed.
   */
  private _redo(step: "push" | "redo" | "undo") {
    if (this._position >= this._stack.length - 1) {
      // return shell.beep();
      return;
    }

    this._position++;

    const element = this._stack[this._position];
    const possiblePromise = element.redo();
    if (possiblePromise instanceof Promise) {
      possiblePromise.then(() => {
        element.notify?.(step);
      });
    } else {
      element.notify?.(step);
    }

    return possiblePromise;
  }

  /**
   * Clears the current undo/redo stack.
   */
  clear() {
    this._stack = [];
    this._position = -1;
  }
}

export { UndoRedo };
