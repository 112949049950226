import React from "react";
import styled from "styled-components";
import Guide from "./Guide";
import Guides from "./Guides";

const GuideAdder = styled(Guide)`
  z-index: 999;
  opacity: 0.4;
`;

const StyleRulerGuides = styled.div`
  position: absolute;
  inset: 24px 0 0 24px;
  overflow: hidden;
  pointer-events: none;
  z-index: 999;
`;

const HorizontalGuides = styled(Guides)`
  height: 100%;

  ${Guide} {
    width: 1px;
    height: 100%;
  }
`;

const VerticalGuides = styled(Guides)`
  width: 100%;

  ${Guide} {
    width: 100%;
    height: 1px;
  }
`;

class RulerGuides extends React.Component {
  state = {
    scrollX: 0,
    scrollY: 0,
    isShowAdder: false,
    adderDirection: "horizontal",
    adderGuide: 0
  };

  scroll({ x, y }) {
    this.setState({
      scrollX: x,
      scrollY: y
    });
  }

  adder(show = false, direction, guide) {
    if (show) {
      this.setState({
        isShowAdder: true,
        adderDirection: direction,
        adderGuide: guide
      });
    } else {
      this.setState({ isShowAdder: false });
    }
  }

  render() {
    const scrollX = this.state.scrollX;
    const scrollY = this.state.scrollY;
    const zoom = this.props.zoom;
    const { horizontalGuides, verticalGuides } = this.props;
    const { adderDirection, adderGuide, isShowAdder } = this.state;

    return (
      <StyleRulerGuides>
        <HorizontalGuides transform={`translateX(${-scrollX * zoom}px)`}>
          <GuideAdder
            show={isShowAdder && adderDirection === "horizontal"}
            transform={`translateX(${adderGuide * zoom}px)`}
          />
          {horizontalGuides.map((value, key) => {
            return <Guide key={key} transform={`translateX(${value * zoom}px)`} />;
          })}
        </HorizontalGuides>
        <VerticalGuides transform={`translateY(${-scrollY * zoom}px)`}>
          <GuideAdder
            show={isShowAdder && adderDirection === "vertical"}
            transform={`translateY(${adderGuide * zoom}px)`}
          />
          {verticalGuides.map((value, key) => {
            return <Guide key={key} transform={`translateY(-50%) translateY(${value * zoom}px)`} />;
          })}
        </VerticalGuides>
      </StyleRulerGuides>
    );
  }
}

// RulerGuides.propTypes = {
//   horizontalGuides: PropTypes.array,
//   verticalGuides: PropTypes.array,
//   zoom: PropTypes.number
// };

RulerGuides.defaultProps = {
  horizontalGuides: [],
  verticalGuides: [],
  zoom: 1
};

export default RulerGuides;
