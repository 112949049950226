import * as React from "react";
import Editor from "./Editor";
import { createStore } from "./Editor/store";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";

/*function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}*/

const store = createStore();
(window as any).store = store;

store.screen.set({
  name: "未命名",
  width: 1024,
  height: 768
});

function DataLoading() {
  return (
    <Fab
      disableRipple
      variant="extended"
      sx={{ position: "absolute", top: "50%", left: "50%", fontSize: "12px", transform: "translate(-50%)" }}
    >
      <CircularProgress size={22} color="inherit" sx={{ mr: 1 }} />
      正在加载屏幕数据...
    </Fab>
  );
}

export default function App() {
  return <Editor store={store} />;
}
