import React from "react";
import { styled } from "@mui/system";
import Workspace from "./Workspace";
import Titlebar from "./Titlebar";
import Toolbar from "./Toolbar";
import Inspector from "./Inspector";
import Hierarchy from "./Hierarchy";

const EditorRoot = styled("div", {
  name: "Editor",
  slot: "Root"
})`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

const WorkspaceWarp = styled("div", {
  name: "WorkspaceWarp"
})`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
`;

interface EditorProps {
  store: any;
}

export default function Editor({ store }: EditorProps) {
  // const ref = React.useRef();

  // React.useEffect(() => {
  // }, []);

  return (
    <EditorRoot>
      <Titlebar store={store} />
      <Toolbar store={store} />
      <WorkspaceWarp>
        <Hierarchy store={store} />
        <Workspace store={store} />
        <Inspector store={store} />
      </WorkspaceWarp>
    </EditorRoot>
  );
}
