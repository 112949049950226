import { customShapes } from "../config";
import "../components/Text";
import "../components/Image";
import "../components/Svg";
import "../components/Chart";

export function createShape(element: any, store: any) {
  const Shape = customShapes.get(element.type);

  return <Shape key={element.id} store={store} element={element as any} />;
}
