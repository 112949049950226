import { makeObservable, action, observable, computed } from "mobx";
import Screen from "./Screen";
import Element from "./Element";
import { UndoRedo } from "./undo-redo";

export class Store {
  constructor() {
    makeObservable(this);
  }

  history = new UndoRedo();
  screen = new Screen();

  @observable zoom: number = 1;
  @observable horizontalGuides: number[] = [];
  @observable verticalGuides: number[] = [];
  @observable selectedElements: Element[] = [];

  @action
  setZoom(zoom: number) {
    this.zoom = zoom;
  }

  @action
  setHorizontalGuides(guides: number[]) {
    this.horizontalGuides = guides;
  }

  @action
  setVerticalGuides(guides: number[]) {
    this.verticalGuides = guides;
  }

  getElementById(id: string) {
    let elements = this.screen.children as Element[];
    return elements.find(el => el.id === id);
  }

  @action selectElements(ids: string[]) {
    const elements = ids.map(id => this.getElementById(id));
    this.selectedElements = elements as Element[];
  }

  toJSON() {}

  loadJSON(json: Record<string, unknown>) {}
}

export function createStore() {
  return new Store();
}
