import React from "react";
import { observer } from "mobx-react";
import { registerShapeComponent } from "../config";
import { styled } from "@mui/system";

const Text = styled("p", {
  name: "TextElement"
})`
  position: absolute;
  margin: 0;
  padding: 0;
`;

export const TextElement = observer(({ element, store }: any) => {
  const ref = React.useRef(null);

  return (
    <Text
      ref={ref}
      id={element.id}
      sx={{ left: element.x, top: element.y, width: element.width, height: element.height }}
    >
      {element.text}
    </Text>
  );
});

registerShapeComponent("text", TextElement);
