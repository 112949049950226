import React from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import { Tree, TreeItem } from "./Tree";
import TitleIcon from "@mui/icons-material/Title";
import ImageIcon from "@mui/icons-material/Image";
import SquareIcon from "@mui/icons-material/Square";
import BarChartIcon from "@mui/icons-material/BarChart";

const HierarchyRoot = styled("div", {
  name: "Hierarchy",
  slot: "root"
})`
  display: flex;
  flex-direction: column;
  width: auto;
  height: 100%;
  border-right: 1px solid #dddddd;
  //color: #6b6b6b;
  //background-color: #ececed;
`;

const HierarchyInner = styled("div", {
  name: "Hierarchy",
  slot: "inner"
})`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 100%;
  color: #6b6b6b;
  background-color: #ececed;
  overflow: auto;
`;

const icons = {
  text: TitleIcon,
  image: ImageIcon,
  svg: SquareIcon,
  chart: BarChartIcon
};

export interface HierarchyProps {
  store: any;
}

function Hierarchy({ store }: HierarchyProps) {
  const selectedElementsIds = store.selectedElements.map((element: any) => element.id);
  const elements = store.screen.children;

  return (
    <HierarchyRoot>
      <HierarchyInner>
        <Tree
          expanded={[]}
          selected={selectedElementsIds}
          onNodeToggle={null}
          onNodeSelect={(e: any, newSelected: any) => {
            const oldSelected = [...selectedElementsIds];
            store.history.push({
              redo() {
                store.selectElements(newSelected);
              },
              undo() {
                store.selectElements(oldSelected);
              }
            });
          }}
          multiSelect
        >
          {elements.map((element: any) => {
            const Icon = (icons as any)[element.type];
            return <TreeItem key={element.id} nodeId={element.id} label={element.name} icon={<Icon />} />;
          })}
        </Tree>
      </HierarchyInner>
    </HierarchyRoot>
  );
}

export default observer(Hierarchy);
