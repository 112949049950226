import { styled } from "@mui/system";
import TreeView from "@mui/lab/TreeView";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import React from "react";
import MTreeItem, { treeItemClasses, useTreeItem } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import clsx from "classnames";

const Tree = styled(props => (
  <TreeView
    aria-label="file system navigator"
    defaultCollapseIcon={<ArrowDropDownIcon />}
    defaultExpandIcon={<ArrowRightIcon />}
    sx={{ flexGrow: 1 }}
    {...props}
  />
))(({ theme }) => ({}));

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const { classes, className, label, nodeId, icon: iconProp, expansionIcon, displayIcon } = props;

  const { disabled, expanded, selected, focused, handleExpansion, handleSelection, preventSelection } =
    useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = event => {
    preventSelection(event);
  };

  const handleExpansionClick = event => {
    handleExpansion(event);
  };

  const handleSelectionClick = event => {
    handleSelection(event);
  };

  return (
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled
      })}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography onClick={handleSelectionClick} component="div" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  /**
   * Override or extend the styles applied to the component.
   */
  classes: PropTypes.object.isRequired,
  /**
   * className applied to the root element.
   */
  className: PropTypes.string,
  /**
   * The icon to display next to the tree node's label. Either a parent or end icon.
   */
  displayIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label. Either an expansion or collapse icon.
   */
  expansionIcon: PropTypes.node,
  /**
   * The icon to display next to the tree node's label.
   */
  icon: PropTypes.node,
  /**
   * The tree node label.
   */
  label: PropTypes.node,
  /**
   * The id of the node.
   */
  nodeId: PropTypes.string.isRequired
};

const CustomTreeItem = props => <MTreeItem ContentComponent={CustomContent} {...props} />;

const TreeItem = styled(props => <CustomTreeItem {...props} />)(({ theme }) => ({
  boxSizing: "border-box",
  [`& .${treeItemClasses.content}`]: {
    height: "24px",
    lineHeight: "24px",
    color: theme.palette.text.primary,
    // borderTopRightRadius: theme.spacing(2),
    // borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightRegular,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover
    },
    "&.Mui-selected": {
      background: theme.palette.action.selected
    },
    "&.Mui-selected.Mui-focused": {
      color: theme.palette.primary.contrastText,
      background: theme.palette.primary.dark
    },
    // "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
    //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
    //   color: "var(--tree-view-color)"
    // },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis"
    }
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2)
    }
  }
}));

export { TreeItem };
export { Tree };
