import React from "react";
import { styled } from "@mui/system";
import { observer } from "mobx-react";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import screenTabs from "./screen-tabs";

const InspectorRoot = styled("div", {
  name: "Inspector",
  slot: "root"
})`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 270px;
  height: 100%;
  border-left: 1px solid #dddddd;
  color: #6b6b6b;
  background-color: #ececed;
`;

const TabPanel = styled("div")`
  flex: 1;
  overflow: auto;
`;

const elementTabs = [
  {
    tab: "排版",
    panel: () => <div>默认element</div>
  }
];

const customsTabs: any = {
  text: [
    {
      tab: "样式",
      panel: () => <div>文本样式</div>
    },
    {
      tab: "排版",
      panel: () => <div>文本排版</div>
    }
  ],
  chart: [
    {
      tab: "样式",
      panel: () => <div>图表样式</div>
    },
    {
      tab: "序列",
      panel: () => <div>图表选项</div>
    },
    {
      tab: "排版",
      panel: () => <div>图表排版</div>
    }
  ]
};

export interface InspectorProps {
  store: any;
}

// const setAttr = (key: string, value: any) => {
//   const oldValue = element[key];
//   store.history.push({
//     redo() {
//       element.set({ [key]: value });
//     },
//     undo() {
//       element.set({ [key]: oldValue });
//     }
//   });
// };
//
// function render() {
//   const types: any = {
//     text: observer(() => {
//       return <Input startAdornment="text" value={element.text} onChange={e => element.set("text", e.target.value)} />;
//     }),
//     chart: observer(() => {
//       return null;
//       // <Input startAdornment="testa" value={element.testa} onChange={e => element.set("testa", e.target.value)} />
//     })
//   };
//   const TypeIns = types[element.type] || null;
//
//   return (
//     <>
//       <Input
//         type="number"
//         startAdornment="x"
//         value={element.x}
//         onChange={e => setAttr("x", Number(e.target.value))}
//       />
//       <Input
//         type="number"
//         startAdornment="y"
//         value={element.y}
//         onChange={e => setAttr("y", Number(e.target.value))}
//       />
//       <Input
//         type="number"
//         startAdornment="width"
//         value={element.width}
//         onChange={e => setAttr("width", Number(e.target.value))}
//       />
//       <Input
//         type="number"
//         startAdornment="height"
//         value={element.height}
//         onChange={e => setAttr("height", Number(e.target.value))}
//       />
//       {<TypeIns />}
//     </>
//   );
// }

function Inspector({ store }: InspectorProps) {
  const element = store.selectedElements[0];

  const tabs = React.useMemo(() => {
    return element ? customsTabs[element.type] || elementTabs : screenTabs;
  }, [element?.type]);

  const [activeTab, setActiveTab] = React.useState<number>(0);
  const index = React.useMemo(() => Math.min(activeTab, tabs.length - 1), [tabs, activeTab]);

  const Panel = tabs[index]?.panel;

  return (
    <InspectorRoot>
      <Tabs
        variant="fullWidth"
        value={index}
        onChange={(e: any, tab: number) => {
          setActiveTab(tab);
        }}
        sx={{ minHeight: 30 }}
      >
        {tabs.map((tab: any, key: number) => (
          <Tab key={key} label={tab.tab} sx={{ minHeight: 30, p: 0 }} />
        ))}
      </Tabs>
      <TabPanel>
        <Panel store={store} />
      </TabPanel>
    </InspectorRoot>
  );
}

export default observer(Inspector);
