import React from "react";
import styled from "styled-components";

const Guides = styled(({ transform, ...props }) => <div style={{ transform }} {...props} />)`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
`;

export default Guides;
