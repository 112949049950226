import React from "react";
import { observer } from "mobx-react";
import { registerShapeComponent } from "../config";
import { styled } from "@mui/system";

const Image = styled("img", {
  name: "ImageElement"
})(() => ({
  position: "absolute",
  display: "block"
}));

export const ImageElement = observer(({ element, store }: any) => {
  const ref = React.useRef(null);

  return (
    <Image
      ref={ref}
      id={element.id}
      src={element.src}
      sx={{ left: element.x, top: element.y, width: element.width, height: element.height }}
    ></Image>
  );
});

registerShapeComponent("image", ImageElement);
