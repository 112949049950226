import React from "react";
import styled from "styled-components";

const Guide = styled(({ show = true, transform, style, ...props }) => (
  <div
    style={{
      ...style,
      display: show ? null : "none",
      transform
    }}
    {...props}
  />
))`
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background-color: #d9a712;
  will-change: transform;
`;

export default Guide;
