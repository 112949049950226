import React from "react";
import { observer } from "mobx-react";
import Input from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const ScreenPanel = observer(({ store }: any) => {
  const screen = store.screen;

  const setScreenState = (key: string, newValue: any, oldValue: any) => {
    store.history.push({
      redo() {
        screen.set({ [key]: newValue });
      },
      undo() {
        screen.set({ [key]: oldValue });
      }
    });
  };

  return (
    <>
      <Stack direction="column" spacing={0.5} sx={{ p: 1 }}>
        <Typography sx={{ fontSize: 12, fontWeight: 600 }}>大小</Typography>
        <Stack direction="row" spacing={0.5}>
          <Input
            type="number"
            size="small"
            startAdornment={<InputAdornment position="start">W</InputAdornment>}
            value={screen.width}
            sx={{ p: 0.5, "& .MuiInputAdornment-root": { mr: 0.5 }, "& .MuiInputBase-input": { p: 0 } }}
            onChange={(e: any) => {
              const newValue = Number(e.target.value);
              const oldValue = screen.width;
              setScreenState("width", newValue, oldValue);
            }}
          />
          <Input
            type="number"
            size="small"
            startAdornment={<InputAdornment position="start">H</InputAdornment>}
            sx={{ p: 0.5, "& .MuiInputAdornment-root": { mr: 0.5 }, "& .MuiInputBase-input": { p: 0 } }}
            value={screen.height}
            onChange={(e: any) => {
              const newValue = Number(e.target.value);
              const oldValue = screen.height;
              setScreenState("height", newValue, oldValue);
            }}
          />
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="column" spacing={0.5} sx={{ p: 1 }}>
        <Typography sx={{ width: 70, fontSize: 12, fontWeight: 600 }}>显示模式</Typography>
        <Select
          fullWidth
          value={screen.viewMode}
          size="small"
          onChange={(e: any, item: any) => {
            const newValue = item.props.value;
            const oldValue = screen.viewMode;
            setScreenState("viewMode", newValue, oldValue);
          }}
        >
          <MenuItem value="default">原始大小</MenuItem>
          <MenuItem value="fit">缩放以合适</MenuItem>
        </Select>
      </Stack>
      <Divider />
      <Stack direction="row" spacing={0.5} sx={{ p: 1 }}>
        <FormGroup row>
          <FormControlLabel
            control={<Checkbox size="small" defaultChecked />}
            label="背景"
            sx={{ "& .MuiTypography-root": { fontSize: 12, fontWeight: 600 } }}
          />
          <Input
            size="small"
            value={screen.background}
            sx={{ p: 0.5, "& .MuiInputAdornment-root": { mr: 0.5 }, "& .MuiInputBase-input": { p: 0 } }}
          />
        </FormGroup>
        {/*<Typography sx={{ width: 70, fontSize: 12, fontWeight: 600 }}>背景</Typography>*/}
      </Stack>
      <Divider />
    </>
  );
});

export default [
  {
    tab: "页面设置",
    panel: ScreenPanel
  }
];
