import { makeObservable, observable } from "mobx";
import Element from "./Element";

export default class TextElement extends Element {
  constructor() {
    super();
    makeObservable(this);
  }

  @observable text = "";
}
