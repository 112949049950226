import { makeObservable, action, observable } from "mobx";

let idCounter = 0;

export type ElementId = string;

export default class Element {
  static set(attrs: Record<string, any>) {
    const element = new this();
    element.set(attrs);
    return element;
  }

  constructor() {
    makeObservable(this);
  }

  // @observable draggable = true;
  // @observable selectable = true;

  parent = null;
  @observable id: string = "element-" + idCounter++;
  @observable type = "element";
  @observable x = 0;
  @observable y = 0;
  @observable width = 0;
  @observable height = 0;
  @observable rotation = 0;
  @observable flipX = false;
  @observable flipY = false;
  @observable style = {};
  @observable attrs = {};
  @observable custom = {};

  @action
  set(attrs: string | any, value?: any) {
    if (typeof attrs === "string") {
      (this as any)[attrs] = value;
    } else {
      Object.assign(this, attrs);
    }
  }
}
