import React from "react";
import { observer } from "mobx-react";
import { registerShapeComponent } from "../config";
import { styled } from "@mui/system";
import * as echarts from "echarts";
import { useSize } from "ahooks";

type EChartsOption = echarts.EChartsOption;

interface ChartProps {
  option?: EChartsOption;
}

const Chart = styled("div", {
  name: "ChartElement"
})(() => ({
  position: "absolute"
}));

export const ChartElement = observer(({ element, store }: any) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const warpSize = useSize(ref) || { width: 0, height: 0 };

  const [chart, setChart] = React.useState<any>(null);

  React.useEffect(() => {
    const chart = echarts.init(ref.current as HTMLDivElement);
    setChart(chart);
  }, []);

  React.useEffect(() => {
    chart && chart.setOption(element.option);
  }, [element.option, chart]);

  React.useEffect(() => {
    chart &&
      chart.resize({
        width: warpSize.width,
        height: warpSize.height
      });
  }, [warpSize]);

  return (
    <Chart
      ref={ref}
      id={element.id}
      sx={{ left: element.x, top: element.y, width: element.width, height: element.height }}
    ></Chart>
  );
});

registerShapeComponent("chart", ChartElement);
