import styled from "styled-components";
import React from "react";
import RulerTrack from "@scena/react-guides";
import Guide from "./Guide";
import Guides from "./Guides";

const RulerRail = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;

const RulerContainer = styled.div`
  user-select: none;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const GuideLabel = styled.div`
  position: absolute;
  top: 1px;
  left: 1px;
  padding: 0;
  font-size: 10px;
  line-height: 10px;
  color: #777;
  background: #eee;
`;

const GuideAdder = styled(Guide)`
  z-index: 999;
`;

const StyledRuler = styled.div.withConfig({ displayName: "Ruler" }).attrs(props => ({
  className: `is-${props.type}`
}))`
  &.is-horizontal {
    ${RulerContainer} {
      cursor: col-resize;
    }

    ${RulerRail} {
      border-bottom: 1px solid #c2c2c2;
    }

    ${Guides} {
      height: 100%;
    }

    ${Guide} {
      width: 1px;
      height: 100%;
    }

    ${GuideLabel} {
      padding: 0 2px;
      left: 2px;
    }
  }

  &.is-vertical {
    ${RulerContainer} {
      cursor: row-resize;
    }

    ${RulerRail} {
      border-right: 1px solid #c2c2c2;
    }

    ${Guides} {
      width: 100%;
    }

    ${Guide} {
      width: 100%;
      height: 1px;
    }

    ${GuideLabel} {
      top: 2px;
      padding: 2px 0;
      transform: rotate(180deg);
      writing-mode: vertical-lr;
    }
  }
`;

export default class Ruler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      guides: props.guides,
      scroll: 0,
      adderGuide: 0,
      isShowAdder: false
    };
  }

  container = React.createRef();
  rulerRail = React.createRef();
  rulerTrack = React.createRef();
  adder = React.createRef();

  getUnit(zoom) {
    let unit = Math.floor((1 / zoom) * 100);
    const levels = [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2, 5, 10];
    for (let i = 0; i < levels.length; i++) {
      const level = levels[i];
      unit = Math.floor((1 / level) * 100);
      if (zoom <= level) break;
    }
    return unit;
  }

  componentDidMount() {
    this.isHorizontal = this.props.type === "horizontal";
    this.isVertical = this.props.type === "vertical";
  }

  isArea = false;
  doing = false;

  rect = null;

  onMouseEnter = e => {
    // console.log("enter");

    this.rect = this.rulerRail.current.getBoundingClientRect();
    this.setState({ isShowAdder: true }, () => {
      this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
    });

    this.isEnter = true;
    this.isLeave = false;
    this.isContainArea = false;
    window.addEventListener("pointermove", this.onDocMouseMove);
    window.addEventListener("pointerdown", this.onDocMouseDown);
  };

  onMouseLeave = e => {
    // console.log("leave");

    this.isEnter = false;
    this.isLeave = true;

    if (!this.doing) {
      this.rect = null;
      this.setState({ isShowAdder: false }, () => {
        this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
      });

      window.removeEventListener("pointermove", this.onDocMouseMove);
      window.removeEventListener("pointerdown", this.onDocMouseDown);
    }
  };

  checkContainArea(point, rect) {
    if (this.isHorizontal) {
      const x = point[0];
      return x >= rect.x && x < rect.x + rect.width;
    } else if (this.isVertical) {
      const y = point[1];
      return y >= rect.y && y < rect.y + rect.height;
    }
    return false;
  }

  onDocMouseMove = e => {
    const scroll = this.state.scroll;
    const zoom = this.props.zoom;

    let adderGuide = 0;
    if (this.isHorizontal) {
      const x = e.clientX - this.rect.x;
      adderGuide = Math.round(x / zoom + scroll);
    } else if (this.isVertical) {
      const y = e.clientY - this.rect.y;
      adderGuide = Math.round(y / zoom + scroll);
    }

    this.isContainArea = this.checkContainArea([e.clientX, e.clientY], this.rect);

    // console.log("move", this.isContainArea);
    this.setState({ adderGuide, isShowAdder: !this.hasGuide(adderGuide) }, () => {
      this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
    });
  };

  onDocMouseDown = e => {
    // console.log("down");
    const { guides, adderGuide } = this.state;
    if (this.hasGuide(adderGuide)) {
      const copyGuides = [...guides];
      const index = copyGuides.indexOf(adderGuide);
      copyGuides.splice(index, 1);
      this.setState({ guides: copyGuides, isShowAdder: true }, () => {
        this.props.onChangeGuides?.(this.state.guides);
        this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
      });
      // console.log("更改");
    }

    this.doing = true;
    window.addEventListener("pointerup", this.onDocMouseUp);
  };

  onDocMouseUp = e => {
    // console.log("up", e.clientX, e.clientY);
    const { guides, adderGuide } = this.state;

    if (this.isContainArea && !this.hasGuide(adderGuide)) {
      // console.log("新增");
      this.setState(
        {
          guides: [...guides, adderGuide],
          isShowAdder: false
        },
        () => {
          this.props.onChangeGuides?.(this.state.guides);
          this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
        }
      );
    } else {
      // console.log("没有新增");
      this.setState({ isShowAdder: false }, () => {
        this.props.onChangeAdder?.({ show: this.state.isShowAdder, guide: this.state.adderGuide });
      });
    }

    this.doing = false;
    window.removeEventListener("pointerup", this.onDocMouseUp);
    if (this.isLeave) {
      window.removeEventListener("pointermove", this.onDocMouseMove);
      window.removeEventListener("pointerdown", this.onDocMouseDown);
    }
  };

  hasGuide(number) {
    return this.state.guides.indexOf(number) !== -1;
  }

  scroll(number) {
    this.rulerTrack.current.scroll(number);
    this.setState({ scroll: number });
  }

  resize() {
    this.rulerTrack.current.resize();
  }

  render() {
    const { type, zoom } = this.props;
    const { guides, scroll, isShowAdder, adderGuide } = this.state;
    const isHorizontal = this.isHorizontal;

    const guidesTransform = `translate${isHorizontal ? "X" : "Y"}(${-scroll * zoom}px)`;
    const adderGuideTransform = `translate${isHorizontal ? "X" : "Y"}(${adderGuide * zoom}px)`;

    const { onChangeGuides, onChangeAdder, ...props } = this.props;
    return (
      <StyledRuler {...props}>
        <RulerContainer onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
          <RulerRail ref={this.rulerRail}>
            <RulerTrack
              ref={this.rulerTrack}
              type={type}
              backgroundColor="#eee"
              lineColor="#c2c2c2"
              textColor="#777"
              direction="end"
              textAlign={isHorizontal ? "left" : "right"}
              textOffset={isHorizontal ? [0, 3] : [3, 0]}
              textFormat={v => v.toLocaleString()}
              zoom={zoom}
              unit={this.getUnit(zoom)}
              lockGuides={true}
              showGuides={false}
              displayDragPos={false}
              dragPosFormat={v => `${v}`}
            />
          </RulerRail>
          <Guides transform={guidesTransform}>
            <GuideAdder show={isShowAdder} transform={adderGuideTransform}>
              <GuideLabel>{adderGuide}</GuideLabel>
            </GuideAdder>
            {guides.map((value, key) => {
              const transform = isHorizontal
                ? `translateX(${value * zoom}px)`
                : `translateY(-50%) translateY(${value * zoom}px)`;
              return (
                <Guide key={key} transform={transform}>
                  <GuideLabel>{value}</GuideLabel>
                </Guide>
              );
            })}
          </Guides>
        </RulerContainer>
      </StyledRuler>
    );
  }
}

// Ruler.propTypes = {
//   // ref: PropTypes.any,
//   type: PropTypes.oneOf(["horizontal", "vertical"]),
//   zoom: PropTypes.number,
//   guides: PropTypes.array(PropTypes.number),
//   onChangeAdder: PropTypes.func,
//   onChangeGuides: PropTypes.func
// };

Ruler.defaultProps = {
  type: "horizontal",
  zoom: 1,
  guides: []
};
